<template>
  <MainLayout>
    <template v-slot:default>
      <DeletePopupCommon
        title="Промокоды"
        description="Вы уверены, что хотите удалить выбранный список промокодов?"
        :dialog="dialog"
        @closeDeletePopup="closeDeletePopup"
        :delete-function="deleteFunction"
      />
      <TableFilters :queries="queries" :loading="isDateLoaded">
        <template v-slot:filter-title>
          <span v-if="sourceObject.count">
            <span class="title-filter">Всего:</span>{{ sourceObject.count }}
            <!--            <span>промокодов</span>-->
          </span>
        </template>
        <template v-slot:download-button>
          <v-btn
            @click="downloadList"
            class="btn btn_color_grey btn-unload flex-right mr-4"
            :disabled="loadingBtn || sourceObject.results.length == 0"
            :loading="loadingBtn"
          >
            Выгрузить
          </v-btn>
        </template>
        <template
          v-slot:add-button
          v-if="currentRole !== 40 && currentRole !== 50"
        >
          <router-link
            to="createPromocodes"
            class="btn btn-add btn_color_green"
          >
            Добавить Промокод
          </router-link>
        </template>
        <template v-if="deleteCkeckboxList.length > 1" v-slot:delete-button>
          <v-btn
            :disabled="isDisabled"
            color="#EB5C6D"
            class="btn btn-delete btn_color_red"
            @click="clickDelete"
            style="color:white"
          >
            Удалить промокоды
          </v-btn>
        </template>
        <template v-else v-slot:delete-button>
          <v-btn
            :disabled="isDisabled"
            color="#EB5C6D"
            class="btn btn-delete btn_color_red"
            @click="clickDelete"
            style="color:white"
          >
            Удалить промокод
          </v-btn>
        </template>
      </TableFilters>
      <TableLayout
        :loading="isDateLoaded"
        :table-data="sourceObject.results"
        :header="header"
        :source-object="sourceObject"
        delete-all
        dropDown
        disable-router
      >
        <template slot-scope="{ itemFather }">
          <v-checkbox
            class="ma-0 pa-0 checkbox-type"
            :value="itemFather.id"
            v-model="deleteCkeckboxList"
            hide-details
          ></v-checkbox>
        </template>
      </TableLayout>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsPromocodes from "@/helpers/tableCols/promocodes";
import DeletePopupCommon from "@/components/popup/DeletePopupCommon";

import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "PromocodesPage",
  data() {
    return {
      loadingBtn: false,
      queries: query.promocode,
      dialog: false // диалоговое окно popup удаления
    };
  },
  components: { TableFilters, TableLayout, MainLayout, DeletePopupCommon },
  computed: {
    deleteCkeckboxList: {
      get() {
        return this.$store.getters.getDeleteList;
      },
      set(value) {
        this.$store.commit("UPDATE_DELETE_LIST", value);
        if (this.$store.getters.getCheckedAll.includes("all")) {
          this.$store.commit("SET_CHECKED_ALL", []);
        }
      }
    },
    sourceObject() {
      return this.$store.getters.getPagePromocodesList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    },
    roles() {
      return this.$store.getters.getRoles;
    },
    currentUserGroupId() {
      return this.$store.getters.getCurrentUserGroupId;
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    header() {
      return tableColsPromocodes;
    },
    isDisabled() {
      return this.deleteCkeckboxList.length <= 0 ? true : false;
    }
  },
  methods: {
    deleteFunction() {
      let data = {
        ids: this.deleteCkeckboxList
      };
      this.$store
        .dispatch("deleteArrPromocodes", { data })
        .then(() => {
          this.isDateLoaded = true;
          this.$store.dispatch("fetchPagePromocodeList").finally(() => {
            this.isDateLoaded = false;
          });
        })
        .then(() => {
          this.$store.dispatch("clearCheckedList");
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.promocode)
          );
        });
    },

    clickDelete() {
      this.dialog = true;
    },
    closeDeletePopup(data) {
      this.dialog = data;
    },
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadPagePromocodesList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: this.urlQuery.query
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPagePromocodeList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    const isDeactivatedStatus = to.query.status === "deactivated";
    const updatedQuery = { ...to.query };

    if (isDeactivatedStatus && !updatedQuery["show-deactivated"]) {
      updatedQuery["show-deactivated"] = "true";
      this.$router.replace({ query: updatedQuery }).then(next);
      return;
    } else if (!isDeactivatedStatus && updatedQuery["show-deactivated"]) {
      delete updatedQuery["show-deactivated"];
      this.$router.replace({ query: updatedQuery }).then(next);
      return;
    }
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPagePromocodeList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  }
};
</script>

<style></style>
